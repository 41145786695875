import md5 from 'md5';
import config from './config.json';

class CountApi {
  public static async getTotalCount(isLive: boolean): Promise<number> {
    const url = isLive ? config['api-base-get'] : config['test-api-base-get'];
    const response = await fetch(url);
    const json = await response.json();
    return json.value;
  }

  public static async increaseTotalCount(isLive: boolean): Promise<number> {
    const url = isLive ? config['api-base-hit'] : config['test-api-base-hit'];
    const response = await fetch(url);
    const json = await response.json();
    return json.value;
  }

  public static async getDateCount(isLive: boolean, date: Date): Promise<number> {
    const url = this.getDailyCountGetUrl(isLive, date);
    const response = await fetch(url);
    const json = await response.json();
    return json.value;
  }

  public static async increaseDateCount(isLive: boolean, date: Date): Promise<number> {
    const url = this.getDailyCountHitUrl(isLive, date);
    const response = await fetch(url);
    const json = await response.json();
    return json.value;
  }

  public static getDailyCountGetUrl(isLive: boolean, date: Date): string {
    const baseUrl = isLive ? config['api-base-get'] : config['test-api-base-get'];
    const dayString = md5(md5(date.toISOString().slice(0, 10)));
    return `${baseUrl}-${dayString}`;
  }

  public static getDailyCountHitUrl(isLive: boolean, date: Date): string {
    const baseUrl = isLive ? config['api-base-hit'] : config['test-api-base-hit'];
    const dayString = md5(md5(date.toISOString().slice(0, 10)));
    return `${baseUrl}-${dayString}`;
  }
}

export default CountApi;
